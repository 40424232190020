import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import usePreviewPublisher from "../../hooks/usePreviewPublisher";
import AudioSettings from "../AudioSetting";
import VideoSettings from "../VideoSetting";
import useStyles from "./styles";

import { Button, Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import { retrieveToken } from "../../api/fetchCreds";
import { updateWaitingRoomStatus } from "../../api/updateWaitingRoomStatus";

export default function WaitingRoom() {
  const classes = useStyles();
  const { push } = useHistory();
  const waitingRoomVideoContainer = useRef();
  const [data, setData] = useState({});

  useEffect(() => {
    const dataToLoad = retrieveToken();
    setUserName(data.username);
    setData(dataToLoad);
    localStorage.setItem("username", data.username);
    setUserName(data.username);
    updateWaitingRoomStatus('checking-equipment', dataToLoad.sessionId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { user, setUser } = useContext(UserContext);
  const [userName, setUserName] = useState();
  // need to find the number of participants and set it in a useEffect with room users as dependance
  const [localAudio, setLocalAudio] = useState(
    user.defaultSettings.publishAudio
  );
  const [localVideo, setLocalVideo] = useState(
    user.defaultSettings.publishVideo
  );
  const [localVideoSource, setLocalVideoSource] = useState(undefined);
  const [localAudioSource, setLocalAudioSource] = useState(undefined);
  let [audioDevice, setAudioDevice] = useState("");
  let [videoDevice, setVideoDevice] = useState("");

  const {
    createPreview,
    destroyPreview,
    previewPublisher,
    logLevel,
    previewMediaCreated,
    deviceInfo,
  } = usePreviewPublisher();

  const handleVideoSource = useCallback(
    (e) => {
      const videoDeviceId = e.target.value;
      setVideoDevice(e.target.value);
      previewPublisher.setVideoDevice(videoDeviceId);
      setLocalVideoSource(videoDeviceId);
    },
    [previewPublisher, setVideoDevice, setLocalVideoSource]
  );

  const handleAudioSource = useCallback(
    (e) => {
      const audioDeviceId = e.target.value;
      setAudioDevice(audioDeviceId);
      previewPublisher.setAudioDevice(audioDeviceId);
      setLocalAudioSource(audioDeviceId);
    },
    [previewPublisher, setAudioDevice, setLocalAudioSource]
  );

  const redirectHttps = useCallback(() => {
    const url = window.location.href;
    if (
      url.toString().indexOf("http://") === 0 &&
      url.toString().indexOf("http://localhost") !== 0
    ) {
      window.location.href = window.location.href
        .toString()
        .replace("http://", "https://");
    } else {
      return;
    }
  }, []);

  const handleJoinClick = () => {
    localStorage.setItem("username", data.username);
    push(`room/${data.room}`);
  };

  const handleAudioChange = useCallback((e) => {
    setLocalAudio(e.target.checked);
  }, []);

  const handleVideoChange = useCallback((e) => {
    setLocalVideo(e.target.checked);
  }, []);

  useEffect(() => {
    redirectHttps();
    if (localStorage.getItem("username")) {
      setUserName(localStorage.getItem("username"));
    }
  }, [redirectHttps]);

  useEffect(() => {
    if (
      localAudio !== user.defaultSettings.publishAudio ||
      localVideo !== user.defaultSettings.publishVideo ||
      localAudioSource !== user.defaultSettings.audioSource ||
      localVideoSource !== user.defaultSettings.videoSource
    ) {
      setUser({
        ...user,
        defaultSettings: {
          publishAudio: localAudio,
          publishVideo: localVideo,
          audioSource: localAudioSource,
          videoSource: localVideoSource,
        },
      });
    }
  }, [
    localAudio,
    localVideo,
    user,
    setUser,
    localAudioSource,
    localVideoSource,
  ]);

  useEffect(() => {
    if (userName !== user.userName) {
      setUser({ ...user, userName: userName });
    }
  }, [userName, user, setUser]);

  useEffect(() => {
    if (previewPublisher && previewMediaCreated && deviceInfo) {
      previewPublisher.getAudioDevice().then((currentAudioDevice) => {
        setAudioDevice(currentAudioDevice.deviceId);
      });
      const currentVideoDevice = previewPublisher.getVideoDevice();
      setVideoDevice(currentVideoDevice.deviceId);
    }
  }, [
    deviceInfo,
    previewPublisher,
    setAudioDevice,
    setVideoDevice,
    previewMediaCreated,
  ]);

  useEffect(() => {
    if (previewPublisher) {
      if (localAudio && !previewPublisher.isAudioEnabled()) {
        previewPublisher.enableAudio();
      } else if (!localAudio && previewPublisher.isAudioEnabled()) {
        previewPublisher.disableAudio();
      }
    }
  }, [localAudio, previewPublisher]);

  useEffect(() => {
    if (previewPublisher) {
      if (localVideo && !previewPublisher.isVideoEnabled()) {
        previewPublisher.enableVideo();
      } else if (!localVideo && previewPublisher.isVideoEnabled()) {
        previewPublisher.disableVideo();
      }
    }
  }, [localVideo, previewPublisher]);

  useEffect(() => {
    if (waitingRoomVideoContainer.current) {
      createPreview(waitingRoomVideoContainer.current);
    }
    return () => {
      destroyPreview();
    };
  }, [createPreview, destroyPreview]);

  return (
    <div className={classes.waitingRoomContainer}>
      <div className={classes.leftSide}>
        <div
            id="waiting-room-video-container"
            className={classes.waitingRoomVideoPreview}
            ref={waitingRoomVideoContainer}
        ></div>

      </div>
      <div className={classes.rightSide}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >

          <form className={classes.form} noValidate>
            <div className={classes.deviceContainer}>
              <VideoSettings
                className={classes.deviceSettings}
                hasVideo={localVideo}
                onVideoChange={handleVideoChange}
              />
              {deviceInfo && previewMediaCreated && localVideo && (
                <div className={classes.mediaSources}>
                  <FormControl>
                    <InputLabel id="video">Source Video</InputLabel>
                    {deviceInfo.videoInputDevices && (
                      <Select
                        labelId="video"
                        id="video"
                        value={videoDevice}
                        onChange={handleVideoSource}
                      >
                        {deviceInfo.videoInputDevices.map((device) => (
                          <MenuItem
                            key={device.deviceId}
                            value={device.deviceId}
                          >
                            {device.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </div>
              )}
              <AudioSettings
                className={classes.deviceSettings}
                hasAudio={localAudio}
                onAudioChange={handleAudioChange}
                logLevel={logLevel}
              />
              {deviceInfo && previewMediaCreated && localAudio && (
                <div className={classes.mediaSources}>
                  <FormControl>
                    <InputLabel id="audio">Source Audio</InputLabel>
                    <Select
                      labelId="audio"
                      id="audio"
                      value={audioDevice}
                      onChange={handleAudioSource}
                    >
                      {deviceInfo.audioInputDevices.map((device) => (
                        <MenuItem key={device.deviceId} value={device.deviceId}>
                          {device.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>
          </form>
        </Grid>
        <Grid
          container
          className={classes.buttonStart}

          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleJoinClick}
            disabled={!data.room || !userName}
          >
            Rejoindre
          </Button>
        </Grid>
      </div>
    </div>
  );
}
